.swiper-slide {

    text-align: center;
    font-size: 18px;


}

.product-image {
    height: 200px;
    background: #fff;
    background: url("https://cdn.yemek.com/mnresize/1250/833/uploads/2021/04/patlicanli-pilav-yemekcom.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 1px solid #ccc;
}

.badge.color-deeporange {
    background: transparent;
    color: #F04B74;
    font-weight: 500;
    font-size: 15px;
}

/* Material Icons Font (for MD theme) */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/MaterialIcons-Regular.e79bfd88.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(/static/media/MaterialIcons-Regular.570eb838.woff2) format('woff2'),
       url(/static/media/MaterialIcons-Regular.012cf6a1.woff) format('woff'),
       url(/static/media/MaterialIcons-Regular.a37b0c01.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}

/* Framework7 Icons Font (for iOS theme) */
@font-face {
  font-family: 'Framework7 Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Framework7Icons-Regular.28db8299.eot);
  src: url(/static/media/Framework7Icons-Regular.19158ff0.woff2) format("woff2"),
       url(/static/media/Framework7Icons-Regular.5fce7c12.woff) format("woff"),
       url(/static/media/Framework7Icons-Regular.11137101.ttf) format("truetype");
}

.f7-icons {
  font-family: 'Framework7 Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
  text-align: center;
}

/* Your app styles here */
