.swiper-slide {

    text-align: center;
    font-size: 18px;


}

.product-image {
    height: 200px;
    background: #fff;
    background: url("https://cdn.yemek.com/mnresize/1250/833/uploads/2021/04/patlicanli-pilav-yemekcom.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    border: 1px solid #ccc;
}

.badge.color-deeporange {
    background: transparent;
    color: #F04B74;
    font-weight: 500;
    font-size: 15px;
}
